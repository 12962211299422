import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = true
const mountEl = document.querySelector('#app');

new Vue({
  render: h => h(App,
      { props:
        {
          //dataset
          ...mountEl.dataset
        }
      }
    ),
}).$mount('#app')