<template>
  <div>
  <div v-if="myJson.questions.length>counter">
    <div class="row justify-content-center align-content-end mb-auto">
      <div class="col-12 col-md-7">
        <p class="question">Вопрос {{counter+1}}/{{this.myJson.questions.length}}</p>
      </div>
    </div>
    <div class="row justify-content-center mb-auto">
      <div class="col-12 col-lg-6">
        <p class="text-center question">{{currentQuestion.question}}</p>
      </div>
    </div>
    <div class="row justify-content-center mb-auto">
      <div @click="sayYes" class="col-12 col-md-3 col-lg-2 mb-3"><a class="button glow">Да</a>
      </div>
      <div @click="sayNo" class="col-12 col-md-3 col-lg-2 mb-3"><a class="button glow">Нет</a>
      </div>
    </div>
    <div class="row justify-content-center mb-auto">
      <div class="arrow-buttons mt-5 mb-5"><a class="button button--arrow-left" href="tests">Назад</a>
      </div>
    </div>
  </div>
  <div v-if="myJson.questions.length<=counter">
        <div class="is_overlay" id="trailer">
          <video id="video" height="100%" autoplay="autoplay" loop="loop" preload="none" poster="/assets/img/video_03_poster.jpg" playsinline="playsinline" muted="muted">
            <source src="/assets/media/video_03.mp4" type="video/mp4"/>
            <source src="/assets/media/video_03.webm" type="video/webm"/>
            <!-- <source src="../img/video_03.ogv" type="video/ogg"/> -->
          </video>
        </div>
        <div class="row justify-content-center align-content-end mb-auto">
          <div class="col-12 col-md-9">
            <p v-if="this.myJson.id!=3" class="question">{{score}} {{scoreText}}</p>
            <p v-if="this.myJson.id==3" class="question">Результаты</p>
            <p class="result">{{result}}</p>
            <a class="link link--white popup-doctor" href="/expert/articles">Статьи для специалистов</a>
            <div class="arrow-buttons mt-5 mb-5 w-100">
              <a class="button button--arrow-left"  @click="restart()">Пройти тест еще раз</a>
              <a class="button button--arrow-right" href="tests">Другие тесты</a>
            </div>
          </div>
        </div>
  </div>
  </div>
</template>

<script>
import test1 from "../assets/test1.json"
import test2 from "../assets/test2.json"
import test3 from "../assets/test3.json"
export default {
  name: 'HelloWorld',
  score: Array,
  myJson: JSON,
  counter: 0,
  currentQuestion: '',
  result:String,
  scoreText:String,
  props: {
    testId:String
  },
  data(){
    return{
      myJson: '',
      currentQuestion: '',
      counter:0,
      score: 0,
      result: '',
      scoreText:'',

    }
  },
  beforeMount(){
    if(this.testId==1){
      this.myJson = test1
    }
    else if(this.testId==2){
      this.myJson = test2
    }
    else{
      this.myJson = test3
    }

  },
  mounted(){
    this.nextQuestion()
  },
  methods: {
    // reset score end start current test again
    restart() {
      this.score = 0
      this.result = ''
      this.scoreText = ''
      this.counter = 0
      this.nextQuestion()
    },
    declOfNum(number, titles) {
      var decCache = [],
          decCases = [2, 0, 1, 1, 1, 2];
      if(!decCache[number]) decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)];
      return titles[decCache[number]];
    },
    bitCount (n) {
      var bits = 0
      while (n !== 0) {
        bits += this.bitCount32(n | 0)
        n /= 0x100000000
      }
      return bits
    },

    bitCount32 (n) {
      n = n - ((n >> 1) & 0x55555555)
      n = (n & 0x33333333) + ((n >> 2) & 0x33333333)
      return ((n + (n >> 4) & 0xF0F0F0F) * 0x1010101) >> 24
    },

    getScore(){
      if(this.myJson.id==3){

        let S = this.bitCount(this.score & (2**4-1))
        let H = this.bitCount(this.score & (2**12-2**4))

        return {"S":S, "H":H};
      }

      return this.score;

    },

    sayYes(){


      let reward=1;

      if(this.currentQuestion.answers.yes)
        reward=parseInt(this.currentQuestion.answers.yes)

      this.score += reward

      this.counter++
      this.nextQuestion()
    },
    sayNo(){
      this.counter++
      this.nextQuestion()
    },
    nextQuestion(){
      if(this.myJson.questions.length>this.counter){
        this.currentQuestion = this.myJson.questions[this.counter]
      } else{
        this.getResult()
      }
    },
    getResult(){
      let score = this.getScore();
      if (this.myJson.id==3) {
        this.myJson.results.forEach(result => {
          if (((score.H>=result.H.min) && (score.H<=result.H.max))&&((score.S>=result.S.min) && (score.S<=result.S.max))) {
            this.result = result.text
          }
        })
      }
      else{
        this.scoreText = this.declOfNum(score, ['балл', 'балла', 'баллов'])
        this.myJson.results.forEach(result => {
          if ((score>=result.min) && (score<=result.max)) {
            this.result = result.text
          }
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
