<template>
  <div id="app">
    <Popup v-if="showPopup" @close="showPopup = false" message='Предупреждение: наш тест не предназначен для самостоятельной постановки диагноза и назначения лекарственных препаратов. Полученный неблагоприятный результат может лишь служить дополнительным подтверждением необходимости обратиться к врачу. Профессиональную консультацию вы можете получить у своего лечащего врача.' />
    <Tests v-bind:test-id="testId"/>
  </div>
</template>

<script>
import Tests from './components/Tests.vue'
import Popup from './components/Popup.vue'

export default {
  name: 'app',
  showPopup: true,
  data(){
    return{
      showPopup: true

    }
  },
  components: {
    Popup,
    Tests
  },
  props:{
    testId: String,
  },
}
</script>

<style>

</style>
