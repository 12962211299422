<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container glow">
                    <div class="modal-body">
                        <p class="text-left">
                            {{message}}
                        </p>
                    </div>
                    <div class="row justify-content-md-center mt-5">
                        <a class="button glow button--small" @click="$emit('close')">Ок</a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    // message:String,
    props: {
        message:String
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    max-width: 90%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #000;
    border-radius: 5px;
    border: 5px #fff solid;
    margin: 0 auto;
    padding: 30px;
    text-align: left;
    white-space: normal;
    transition: all .3s ease;
}

.modal-container p {
    font-size: 0.9em;
    line-height: 1.5;
    margin-top: 0;
}

@media (min-width: 1200px) {
    .modal-container {
        max-width: 40%;
    }
}

@media (min-width: 768px){
    .modal-container {
        max-width: 80%;
    }
}
@media (min-width: 576px){
    .modal-container {
        max-width: 80%;
    }
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>